import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
// import {ActionGetListUser, ActionInit, ActionMasterData} from '../../../../data/hooks/user';
import {ActionGetListCustomer} from '../../../../data/hooks/customer';
import {ActionGetListSubscriptionCustomer} from '../../../../data/hooks/subscriptionCustomer';
import {
    ActionGetListSubscriptionGeneralPackage,
    ActionUpdateGeneralPackage
} from '../../../../data/hooks/subscriptionGeneralPackage';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, InputNumber, Typography, Popconfirm} from 'antd'

// ============== SELECT DATA ==============
// import {selectListUser, selectMasterData} from '../../../../data/reselects/userSelector';
import {selectListCustomer} from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectMasterData,
    selectListSubscriptionGeneralPackage
} from '../../../../data/reselects/subscriptionGeneralPackageSelector';
import SubscriptionGeneralPackageResponse, {
    listCols
} from "../../../../data/mapping/Response/SubscriptionGeneralPackageResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {constants} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";


const ModelFields = Object.getOwnPropertyNames(new SubscriptionGeneralPackageResponse());
const Index = props => {
    const namePage = "subscription_general_package",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ["action"], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        [editingKey, setEditingKey] = useState(''),
        isEditing = (record) => record.id === editingKey,
        componentTable = {
            price: {
                width: 250,
                editable: true,
                // onCell: (record) => ({
                //     record,
                //     inputType: "number",
                //     editing: isEditing(record),
                // }),
            },
            limit_download: {
                width: 250,
                editable: true,
            },
            action: {
                width: 150,
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <Typography.Link
                                onClick={() => save(record.key)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                              Save
                            </Typography.Link>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                              <a>Cancel</a>
                            </Popconfirm>
                          </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            },
            // action: {
            //     width: 100,
            //     align: 'center',
            //     key: 'action',
            //     fixed: 'right',
            //     render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
            //                                     onClick={() => props.history.push((PERMISSION.SUBSCRIPTION.GENERAL_PACKAGE_VIEW.route).replace(":id", record.id), {
            //                                         group: PERMISSION.SUBSCRIPTION.GENERAL_PACKAGE_VIEW.group,
            //                                         withParams: true
            //                                     })}/>,
            // }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionGeneralPackages = ActionGetListSubscriptionGeneralPackage(),
        actionUpdateGeneralPackage = ActionUpdateGeneralPackage(),
        // getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListSubscriptionGeneralPackages = useSelector(selectListSubscriptionGeneralPackage()),
        itemErrors = useSelector(selectErrors()),
        // itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListSubscriptionGeneralPackages?.result || []).length < 1) {
            getListSubscriptionGeneralPackages();
        }
        // if (Object.keys(itemMasterData).length < 1) {
        // getMasterData()
        // }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionGeneralPackages?.result || [])
        setPagination({
            total: itemListSubscriptionGeneralPackages?.total || 0,
            totalPage: itemListSubscriptionGeneralPackages?.total_page || 0,
            defaultCurrent: itemListSubscriptionGeneralPackages?.page_index || 1,
            pageSize: itemListSubscriptionGeneralPackages?.page_size || 10
        })
    }, [itemListSubscriptionGeneralPackages]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        getListSubscriptionGeneralPackages(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.PACKAGE.UPDATE.route).replace(":id", data.id))
        } else {
            // props.history.push(PERMISSION.PACKAGE.CREATE.route, {group: PERMISSION.PACKAGE.CREATE.group})
            // props.history.push(PERMISSION.PACKAGE.CREATE.route)
        }
    };

    const onSearch = (val) => {
        actionUpdateGeneralPackage({...val, id: editingKey})
        setEditingKey('');
        // let getFilters = {...paramsFilters, ...val, page_index: 1};
        // setPramsFilters(getFilters)
        // getListSubscriptionGeneralPackages(removeObjectNullFull(getFilters));
    };

    const edit = (record) => {
        form.setFieldsValue({
            price: record.price,
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = (key) => {
        form.submit();
    };

    const mergedColumns = columnsTable({
        titleTable: initData.titleItemTable,
        component: componentTable,
        itemName: namePage
    }).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'price' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return <>
        <HeaderAction title="Subscription General Package List" disabledFormatUpperCaseTitle/>
        <Form form={form} component={false} onFinish={onSearch}>
            <ListComponent
                scroll={{x: 1000}}
                classParent="m-0 p-0 col-md-12 mt-4"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], initData.modelItem)}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                pagination={false}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </Form>
    </>
}

export default Index

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
